<template>
  <div class="myCard">
    <div class="title">
      <span>激活会员卡</span>
    </div>
    <div v-if="VIP == 0" class="form">
      卡号：
      <el-input v-model="card_num" class="input" placeholder="请输入内容" />
      <br />密码：
      <el-input v-model="password" type="password" class="input" placeholder="请输入内容" />
      <br />
      <el-button type="primary" class="button" @click="edit()">确定</el-button>
    </div>
    <div v-else class="right">
      <div class="icon">
        <img src="@/assets/img/user_info/right.png" alt="" />
        <p>您已激活会员卡</p>
      </div>
      <ul class="info">
        <li>您注册的会员卡号：{{ userInfo.account }}</li>
        <li>该卡号绑定的手机号：{{ userInfo.mobile }}</li>
        <li>您可以通过手机号+密码的形式登录</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MyScard",
  data() {
    return {
      VIP: 0,
      card_num: "",
      password: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.VIP = this.userInfo.is_vip;
  },
  methods: {
    edit() {
      this.$fecth
        .post("user_info/band_card", {
          account: this.card_num,
          pwd: this.password,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              showClose: true,
              message: "更新成功！",
              type: "success",
            });
            this.$router.push("/user/info");
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.myCard {
  min-height: 563px;
  background-color: #ffffff;
  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }
  .form {
    padding: 20px;
    width: 400px;
    min-height: 410px;
    margin: 60px auto;
    .input {
      width: 80%;
      margin-bottom: 20px;
    }
    .button {
      margin-top: 40px;
      width: 93.5%;
    }
  }
  .right {
    width: 350px;
    margin: 60px auto;
    padding: 40px;
    border: 1px solid #dedede;
    .icon {
      margin: 30px 0;
      img {
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
      }
      p {
        text-align: center;
        font-size: 25px;
        color: #2771fa;
      }
    }
    .info {
      li {
        content: ".";
        color: rgb(245, 29, 29);
      }
    }
  }
}
</style>
